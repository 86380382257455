.checkpoint {
    margin-top: 2rem;
    h1 {
        font-family: 'Baloo Tamma 2', cursive !important;
        font-size: 3vmax !important;
    }

    .goal-active-task {
        cursor: pointer;
        .icon {
            opacity: .2;
            &.completed {
                opacity: 1;
            }
        }
    }
}

.confirm-goal-combine {
    .tree-view {
        margin: 1rem 0;
        .tree-view-parent, .tree-view-child {
            padding: 1rem;
            background-color: #f8f8f8;
            border-radius: .3rem;
            border: 1px solid #ccc;
            margin-bottom: .6rem;
        }

        .tree-view-child {
            position: relative;

            &::before {
                content: '';
                width: 1rem;
                position: absolute;
                left: -1rem;
                border-bottom: 2px solid #ccc;
                border-left: 2px solid #ccc;
                height: 4.4rem;
                top: -2.7rem;
            }

           
            &.first-child {
                &::before {
                    content: '';
                    width: 1rem;
                    position: absolute;
                    left: -1rem;
                    border-bottom: 2px solid #ccc;
                    border-left: 2px solid #ccc;
                    height: 2.4rem  !important;
                    top: -.7rem !important;
                }
            }

            margin-left: 2rem;
        }

        .new-parent {
            background-color: white;
        }
    }
    
    
}
