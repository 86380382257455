.goal-list {

    .top-level-goal {
        padding: 1rem 1rem;
        cursor: pointer;
        border-radius: .3rem;
        font-size: 2rem;
        
        &:hover {
            background-color: #c6c6c6;

            h2 {
                color: #333 !important;
            }
        }

        h2 {
            color: #555 !important;
        }
    }

    .sub-goal {
        position: relative;

        &::before {
            content: '';
            background-color: #bbb;
            position: absolute;
            top: .5rem;
            left: .5rem;
            height: calc(100% - 1rem);
            width: .5rem;
            cursor: move;
        }

        padding: 1rem 1rem 1rem 2rem;
        cursor: pointer;
        border-radius: .3rem;
        border: 1px solid #bbb;
        margin-bottom: .5rem;
        margin-left: 2rem;

        &:hover {
            background-color: #c6c6c6;

            &::before {
                background-color: #666;
            }            
        }

        .sub-goal-name {
            vertical-align: middle;
            font-size: 1.3rem;
        }
    }

    .top-level-goal, .sub-goal {
        &> .icon {
            display: inline-block;
            margin-right: 1rem;
            font-size: 2rem;
            line-height: 2rem;
            vertical-align: middle;

            &.thumbs.up {
                opacity: .6;
                color: green;
                &:hover {
                    opacity: 1;
                }
            }

            &.thumbs.down {
                opacity: .6;
                color: red;
                &:hover {
                    opacity: 1;
                }
            }

            &.completed {
                opacity: 1;
            }
        }
    }
}