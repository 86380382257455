.habits {
    h1 {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 800 !important;
        font-size: 5rem !important;
        letter-spacing: -.1rem !important;
        margin-top: 2rem !important;
    }

    h2 {
        font-family: 'Roboto', sans-serif !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .date-selector {
        text-align: center;
        margin-bottom: 2rem;

        .date-selector-day {
            display: inline-block;
            padding: 2.6vmin;
            background-color: #ddd;
            margin-right: 1vmin;
            border-radius: 1vmin;
            text-align: center;
            color: #666;
            position: relative;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            .date-selector-day-number {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 800 !important;
                font-size: 7vmin;
                line-height: 6vmin;
                margin-bottom: 1vmin;
            }

            .date-selector-day-week {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 400 !important;
                font-size: 2.5vmin;
                line-height: 2vmin;
            }

            .date-success {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1vmin;
                border-radius: 0 0 1vmin 1vmin;
                overflow: hidden;

                .date-success-percent {
                    height: 1vmin;
                }
            }

            &.good {

                &.active {
                    color: white;
                    background-color: #6FCF97;
                }

                .date-success {
                    background-color: #DDFFEB;
                    .date-success-percent {
                        background-color: #6FCF97;
                    }
                }
            }

            &.bad {
                &.active {
                    color: white;
                    background-color: #EB5757;
                }

                .date-success {
                    background-color: #EAD9D9;
                    .date-success-percent {
                        background-color: #EB5757;
                    }
                }
            }
        }
    }

    .list {
        margin: .5rem 0 2rem 0 !important;
    }
}

.habit-timeline {
    position: relative;
    padding: .5rem 1rem 1rem 0rem;
    margin-bottom: 1rem;
    padding-right: 5rem;
    font-size: 1.2rem;

    .progress-bar {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        // height: .3rem;
        display: flex;
        flex-flow: row nowrap;
        line-height: .5rem;
        padding: .3rem 0 .3rem 0;

        .habit-check {
            margin-top: -2.3rem;

            button {
                color: #ccc;
                width: 3.6rem;
            }

            &.done.positive {
                button {
                    background-color: #6FCF97;
                    color: white;
                }
            }

            &.done.negative {
                button {
                    background-color: #EB5757;
                    color: white;
                }
            }
        }

        .progress-bar-tick {
            border-radius: .25rem;
            margin-right: .5rem;
            width: 100%;
            height: .3rem;
            background-color: rgba(255, 255, 255, 0.2);

            &:last-child {
                margin-right: 0;
                // margin-top: -6rem;
                // height: 6rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &.done {
                background-color: rgba(#6FCF97, .9);
                &.negative {
                    background-color: rgba(#EB5757, .9);
                }
            }
        }
    }
}
