.date-carousel {
    margin: 2rem 0;
    text-align: center;
    .date-item {
        position: relative;
        display: inline-block;
        margin: 0 1rem;
        opacity: .3;
        border: 5px solid #666;
        border-radius: 100% !important;
        width: 4.5rem;
        height: 4.5rem;
        padding: .6rem;
        vertical-align: middle;
        cursor: pointer;

        .date {
            text-align: center;
            font-size: 1.6rem;
            margin-bottom: -0.1rem;
        }
        .month {
            text-align: center;
            font-size: .8rem;
        }

        &:hover {
            opacity: .6;
        }

        &.active-date {
            opacity: 1;
            width: 6rem;
            height: 6rem;
            padding: 1.1rem;

            .date {
                text-align: center;
                font-size: 3rem;
                margin-bottom: .6rem;
            }
            .month {
                text-align: center;
                font-size: 1rem;
            }
        }

        .ring1 {
            position: absolute;
            top: -3px;
            left: -3px;
        }

        .ring2 {
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }
}