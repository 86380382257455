$list-item-spacing: 0rem;

.no-day-goals {
    padding: 4rem 0;
    color: #666;
    text-align: center;
}

.goal-tree {
    margin: 2rem 0 4rem 0 !important;

    .goal-item {
        margin: $list-item-spacing 0;
        padding: 0 !important;
        position: relative;

        .goal-drag-handle {
            position: absolute;
            top: calc(50% - .8rem);
            left: .6rem;
            z-index: 10;
            color: #aaa;

            &:hover {
                color: #333;
            }
        }

        .goal-item-content {
            .goal-sub-tree {
                margin: 0;
                padding: 0;
            }

            .goal-item-name {
                padding: 1rem 1rem 1rem .5rem;
                border-radius: .3rem;
                position: relative;
                font-size: 1.4rem;
                font-family: 'Roboto' !important;
                background-color: #c6c6c6;
                white-space: nowrap;

                .goal-item-progress {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .5rem;
                }

                .goal-item-name-link {
                    display: inline-block;
                    white-space: normal;
                    width: 60%;
                    vertical-align: middle;
                }

                .top-goal {
                    font-size: 1.6rem;
                    font-weight: 700 !important;
                    color: #27AE60;
                }

                .goal-add, .goal-check {
                    position: absolute;
                    top: .9rem;
                    right: .8rem;
                    width: 3.7rem;
                    text-align: center;
                }

                .goal-check {
                    opacity: .1;

                    &.checked {
                        opacity: 1;
                        color: #27AE60;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }

                &:hover {
                    cursor: pointer;

                    .goal-check {
                        display: block;
                    }
                }

                .progress-bar {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: rgba(255, 255, 255, 0.2);
                    height: 5px;
                    display: flex;
                    flex-flow: row nowrap;

                    .progress-bar-tick {
                        margin-right: 3px;
                        width: 100%;
                        height: 5px;
                        background-color: rgba(255, 255, 255, 0.2);

                        &:last-child {
                            margin-right: 0;
                            margin-top: -6rem;
                            height: 6rem;
                        }

                        &.done {
                            background-color: rgba(#6FCF97, .9);
                            &.negative {
                                background-color: rgba(#EB5757, .9);
                            }
                        }
                    }
                }
            }

            .goal-inline-form {
                display: flex;

                .inline-add-new-subgoal {
                    width: 100% !important;
                    padding: 1rem;
                    display: inline-block;
                    padding-left: 3.2rem;
                    position: relative;

                    .inline-form-cta {
                        margin-left: 0rem;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.hidden {
            opacity: 0.1;
            // height: 1rem;
        }
    }
}