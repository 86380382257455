.quote {
    h1 {
        font-family: 'Baloo Tamma 2', cursive !important;
        // text-transform: uppercase !important;
        line-height: 11vmin !important;
        color: #333 !important;
        font-size: 12vmin !important;
        letter-spacing: -0.5vmin;
    }

    .powered-by {
        margin-bottom: 5vh;
        margin-top: 8vh;
        vertical-align: bottom;
        text-align: right;

        .powered-by-label {
            vertical-align: bottom;
            display: inline-block;
            font-size: 1.5vh;
            color: #999;
            margin-right: .3vh;
        }

        img {
            display: inline-block !important;
        }
    }
}

body {
    background-color: #ccc !important;
}
