.dashboard {

    font-family: 'Roboto', sans-serif !important;
    padding-bottom: 8rem;

    h1 {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 800 !important;
        font-size: 5rem !important;
        letter-spacing: -.1rem !important;
        margin-top: 2rem !important;
    }

    .date-selector {
        text-align: center;

        .date-selector-day {
            display: inline-block;
            padding: 2.6vmin;
            background-color: #ddd;
            margin-right: 1vmin;
            border-radius: 1vmin;
            text-align: center;
            color: #666;
            position: relative;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            .date-selector-day-number {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 800 !important;
                font-size: 7vmin;
                line-height: 6vmin;
                margin-bottom: 1vmin;
            }

            .date-selector-day-week {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 400 !important;
                font-size: 2.5vmin;
                line-height: 2vmin;
            }

            .date-success {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1vmin;
                border-radius: 0 0 1vmin 1vmin;
                overflow: hidden;

                .date-success-percent {
                    height: 1vmin;
                }
            }

            &.good {

                &.active {
                    color: white;
                    background-color: #6FCF97;
                }

                .date-success {
                    background-color: #DDFFEB;
                    .date-success-percent {
                        background-color: #6FCF97;
                    }
                }
            }

            &.bad {
                &.active {
                    color: white;
                    background-color: #EB5757;
                }

                .date-success {
                    background-color: #EAD9D9;
                    .date-success-percent {
                        background-color: #EB5757;
                    }
                }
            }
        }
    }
}