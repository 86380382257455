.goal-details {
    margin: 2rem 0;
    font-family: 'Roboto', sans-serif !important;
    padding-bottom: 8rem;

    h1, h3, h4 {
        font-family: 'Roboto', sans-serif !important;
    }

    h1 {
        font-weight: 800 !important;
        font-size: 5rem !important;
        letter-spacing: -.1rem !important;
        margin-top: 2rem !important;
        line-height: 4.6rem !important;
        margin-bottom: 1rem !important;
    }

    h4 {
        font-weight: 400 !important;
        font-size: 1.2rem !important;
        margin-top: 0 !important;
        color: #666 !important;
        margin-bottom: 2rem !important;
    }

    .metric {
        height: 7rem;
        background-color: #ddd !important;

        .metric-value {
            padding-top: .7rem !important;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 100 !important;
            font-size: 2.3rem;
            line-height: 2.3rem;
        }
        .metric-label {
            color: #999;
        }
    }
}