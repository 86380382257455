.tasks {
    h1 {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 800 !important;
        font-size: 5rem !important;
        letter-spacing: -.1rem !important;
        margin-top: 2rem !important;
    }

    h2 {
        font-family: 'Roboto', sans-serif !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .goal-group {
        margin-bottom: 2rem;

        .no-tasks {
            opacity: .3;
        }
    }

    .date-selector {
        text-align: center;
        margin-bottom: 2rem;

        .date-selector-day {
            display: inline-block;
            padding: 2.6vmin;
            background-color: #ddd;
            margin-right: 1vmin;
            border-radius: 1vmin;
            text-align: center;
            color: #666;
            position: relative;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            .date-selector-day-number {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 800 !important;
                font-size: 7vmin;
                line-height: 6vmin;
                margin-bottom: 1vmin;
            }

            .date-selector-day-week {
                font-family: 'Roboto', sans-serif !important;
                font-weight: 400 !important;
                font-size: 2.5vmin;
                line-height: 2vmin;
            }

            .date-success {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1vmin;
                border-radius: 0 0 1vmin 1vmin;
                overflow: hidden;

                .date-success-percent {
                    height: 1vmin;
                }
            }

            &.good {

                &.active {
                    color: white;
                    background-color: #6FCF97;
                }

                .date-success {
                    background-color: #DDFFEB;
                    .date-success-percent {
                        background-color: #6FCF97;
                    }
                }
            }

            &.bad {
                &.active {
                    color: white;
                    background-color: #EB5757;
                }

                .date-success {
                    background-color: #EAD9D9;
                    .date-success-percent {
                        background-color: #EB5757;
                    }
                }
            }
        }
    }

    .list {
        margin: .5rem 0 2rem 0 !important;
    }

    .task-item {
        vertical-align: middle;
        .button {
            background-color: transparent;
            vertical-align: middle;
        }

        .task-name {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0 !important;
            padding-left: 0 !important;
            font-size: 1.2rem;
            margin-top: 1rem;
            line-height: 1.6rem;
        }
    }
}


